
import {
  defineComponent, PropType,
} from 'vue'
import { orderBy } from 'lodash-es'

export interface Column {
  key: string
  title: string
  canSort?: boolean
  hide?: boolean
  size?: string
}
export interface Row {
  [key: string]: string | boolean | number
}

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    columns: {
      type: Object as PropType<Column[]>,
      required: true,
    },
    rows: {
      type: Object as PropType<Row[]>,
      required: true,
    },
  },
  data() {
    return {
      sortBy: '',
      sortDirection: SortDirection.ASC,
    }
  },
  computed: {
    data(): Row[] {
      if (this.sortBy) {
        return orderBy([...this.rows], this.sortBy, this.sortDirection)
      }

      return [...this.rows]
    },
    sortDirectionTitle(): string {
      if (!this.sortDirection) {
        return ''
      }

      return this.sortDirection === 'asc' ? this.mixWB('W: Stigende') : this.mixWB('W: Faldende')
    },
  },
  methods: {
    setSortingOnLoad(): void {
      const firstCanSortColumn = this.columns.find((x) => x.canSort)

      if (firstCanSortColumn) {
        this.sortBy = firstCanSortColumn.key
      }
    },
    onToggleSort(key: string): void {
      if (!this.columns.find((col) => col.key === key)?.canSort) {
        return
      }

      if (this.sortBy === key) {
        this.sortDirection = this.sortDirection === SortDirection.ASC
          ? SortDirection.DESC : SortDirection.ASC
      }
      else {
        this.sortDirection = SortDirection.ASC
        this.sortBy = key
      }
    },
  },
  created() {
    this.setSortingOnLoad()
  },
})
