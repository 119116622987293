
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Edit',
  emits: ['edit-click'],
  methods: {
    onEditClick() {
      this.$emit('edit-click')
    },
  },

})
